:root {
  --blue : #571AFF;
  --indigo : #6610F2;
  --purple : #571AFF;
  --pink : #E83E8C;
  --red : #DC3545;
  --orange : #FD7E14;
  --yellow : #FFD75E;
  --green : #28A745;
  --teal : #20C997;
  --cyan : #17A2B8;
  --white : #FFFFFF;
  --gray : #DFDFED;
  --gray-dark : #232323;
  --primary : #666EE8;
  --secondary : #5e5e5e;
  --success : #28D094;
  --info : #1E9FF2;
  --warning : #FF9149;
  --danger : #FF4961;
  --light : #F8F9FA;
  --dark : #2d3436;
  --base : #9E9E9E;
  --midnight : #010035;
  --breakpoint-xs : 0;
  --breakpoint-sm : 576px;
  --breakpoint-md : 768px;
  --breakpoint-lg : 992px;
  --breakpoint-xl : 1200px;
  --font-family-sans-serif : 'Montserrat', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --font-family-monospace : SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

body {
  background: #fff;
  margin-top: 0px !important;
  overflow: hidden !important;
  height: 100%;
}

.resources__sideRight {
  width: 100%;
  padding: 28px 0;
  padding-left: 30px;
}

#root, .App, .st-main-wrapper, .st-main-body-wrapper {
  height: 100%;
  overflow: hidden !important;
}
input[type="text"]:disabled, input[type="number"]:disabled {
  background: rgba(0, 0, 0, 0);
}
.swal2-modal .swal2-confirm {
  background-color: #0077ff !important;
}
.swal2-styled:focus {
  outline: 0 !important;
  box-shadow: 0 4px 6px 0 rgba(73, 128, 190, 0.5) !important;
}
.swal2-popup {
  box-shadow: 0 4px 6px 0 rgb(198, 215, 234) !important;
}
.container-class{
  width: 25rem !important;
  height: 5rem !important;
  margin-top: 5.5rem;
}

.font-s{
  font-size: 12px !important;
}

.ui.segment {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
}
small {
  font-size: 85% !important;
}

//===========
// Navbar
//===========
.navbar {
  width: 100%;
  height: 100px;
  position: sticky !important;
  top: 0;

  border-bottom: 1px solid var(--gray) !important;
  border-left: 1px solid var(--gray) !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}


//===========
// Sidebar
//===========
.sidebar-wrapper {
  .ui.segment {
    border-radius: 0 !important;
    border: none !important;
  }

  .ui.segment.pushable {
    width: 100%;
    height: 100%;
    margin: 0;
    border: none !important;
    overflow: hidden;

    .pusher {
      overflow-x: hidden !important;
      overflow-y: scroll !important;
      -webkit-overflow-scrolling: touch;
      height: 100vh;
      width: 100%;
      z-index: 1;
      
    }

    .main-panel {
      width: 75% !important;
      margin: auto !important;
      float: none !important;
    }
  }
}

//===========
// Table
//===========

.ui{

  .table{
    border: none !important;
    }

}


//===========
// Card
//===========
.card {
  box-shadow: none;
  border: solid 1.2px var(--gray);
}


//===========
// Backgrounds
//===========
.bg-dark {
  background-color : var(--dark) !important;
  color : var(--white) !important;
}


//===============
// Range Input
//===============
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 9.35px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6.3px;
  cursor: pointer;
  background: var(--blue);
  border-radius: 25px;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border: 0px solid var(--blue);
  height: 25px;
  width: 25px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9.35px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--blue);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 6.3px;
  cursor: pointer;
  box-shadow: 1.4px 1.4px 2.6px #000000, 0px 0px 1.4px #0d0d0d;
  background: var(--blue);
  border-radius: 25px;
  border: 0px solid #000101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 0px 5px rgba(0, 0, 0, 0.2);
  border: 0px solid #000000;
  height: 25px;
  width: 15px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 6.3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: var(--blue);
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 1.4px 1.4px 2.6px #000000, 0px 0px 1.4px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: var(--blue);
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 1.4px 1.4px 2.6px #000000, 0px 0px 1.4px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.2);
  border: 0px solid #000000;
  height: 25px;
  width: 15px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  height: 6.3px;
}
input[type=range]:focus::-ms-fill-lower {
  background: var(--blue);
}
input[type=range]:focus::-ms-fill-upper {
  background: var(--blue);
}



//===========
// Donate Button
//===========
.donate-btn{
  display: inline-block;
  padding: 1rem 1rem;
  border-radius: 25px !important;
  box-shadow: 0 8px 12px 0 rgba(73, 128, 190, 0.5) !important;

  &:hover,
  &:focus{
    outline: none;
    color: #fff !important;
    background-color: var(--blue) !important;
    box-shadow: 0 4px 6px 0 rgba(73, 128, 190, 0.5) !important;
  }
}




//===========
// Colors
//===========
.green {
  color: var(--green);
}
.red {
  color: var(--red);
}
.gray {
  color: var(--secondary) !important;
}
.dark {
  color: var(--dark) !important;
}

img.ui.image.social-link {
  transition: all .2s ease-in-out;
}
img.ui.image.social-link:hover {
  transform: scale(1.2);
}



/* right bounce */
@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
/* /right bounce */

.ui.modal{
  position: relative !important;
  margin: auto !important;
  background: transparent !important;
  .ui.active.modal {
    display: block;
    margin-top: 10rem !important;
    margin: auto !important;
  }
  .actions {
    text-align: center !important;
}
}



.desktop-menu {
  display: grid;
  grid-template-rows: 42px auto auto;
  //-webkit-app-region: drag;

  header {
    display: grid;
    grid-template-columns: 100%;
    padding: 8px;
    background-color: #0867d4;

    .title-drag{
      background: transparent;
      width: 90%;
      padding: 20px;
      -webkit-app-region: drag;
      position: absolute;
      top: 0;
      left: 0;
    }

    strong {
      color: #fff;
    }

    nav {
      justify-self: right;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        cursor: pointer;

        li {
          float: left;
          margin-left: 10px;
          cursor: pointer;

          &:hover{
            opacity: 0.8;
            transform: scale(0.89);
          }
        }
      }
    }
  }
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  max-height: 200px;
}
.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}

.react-multi-carousel-dot-list {
  position: absolute;
  background: transparent;
  width: 15%;
  margin: auto;
  bottom: -30px;
  display: flex;
  left: 18.5%;
  //right: 0;
  justify-content: center;
  padding: 0;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}
.react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background .5s;
  border-width: 2px;
  border-style: solid;
  border-color: var(--blue);
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}
.react-multi-carousel-dot button:hover:active {
  background: var(--blue);
}
.react-multi-carousel-dot--active button {
  background: var(--blue);
}
.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  padding: 0 2.5px 0 2.5px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }
  .react-multi-carousel-track {
    overflow: visible !important;
  }
}