[data-theme="dark"] {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: #ffffff;
    }

    body {
        background: var(--dark);
    }

    .st-top-header {
        max-width: 1170px;
        padding: 70px 30px 0px 30px;
        margin-bottom: -5rem !important;
        margin: auto;

        .content,
        .sub.header {
            color: #fff !important;
        }

        .st-card {
            background-color: transparent;
            border: none;
        }

        .ui.message {
            width: 100%;
            padding: 30px !important;
            margin-bottom: 3rem !important;
            border: solid 1px #2f2f2f !important;
            background-color: var(--blue);
            box-shadow: none;

            .st-button,
            .ui.button.st-button {
                color: #fff;
                border: solid 1px var(--dark);
                background-color: var(--dark);
            }
        }

        .close.icon {
            z-index: 999;
            color: var(--gray-dark);
        }
    }

    /* Track */
    .st-main-body,
    .st-main-sidebar,
    .st-main-body-swap {
        &::-webkit-scrollbar-track {
            background: var(--gray-dark);
        }
    }

    .st-main-body {
        background-color: var(--gray-dark);
    }

    .st-main-body-rex {
        background-color: var(--gray-dark);
    }

    .st-header {
        background-color: var(--midnight);
        //border-bottom: solid 1px var(--secondary);
    }

    .st-login-wrap {
        display: flex;

        &--theme-switch {
            border-left: solid 1.2px var(--white);
        }
    }

    .st-main-sidebar {
        border-right: solid 1px var(--secondary);
        background-color: #2f2f2f;

        &__toggle.ui.button {
            background-color: #2f2f2f;

            &:hover,
            &:focus {
                background-color: #2f2f2f;
            }
        }
    }

    .st-card {
        border: solid 1px #2f2f2f;
        background-color: #2f2f2f;

        h5 {
            color: var(--secondary);
        }

        p {
            color: #fff;
        }

        &__description,
        &__token-name {
            color: #fff;
        }

        &__rex-menu {
            border-bottom: solid 1px var(--gray-dark);
            padding: 22px;
            margin: 20px 0;
        }

        .border {
            border: solid 1px var(--gray-dark) !important;
        }
    }

    .st-card-rex {
        background-color: #2f2f2f !important;
    }

    input {
        background-color: transparent;
    }

    input::placeholder {
        color: var(--secondary);
    }

    .divider-horizontal {
        background-color: var(--gray-dark);
    }

    .st-resources {
        &__side {
            border-right: 1px solid var(--gray-dark);
        }

        &__title {
            color: #ffffff;
        }

        &__info p {
            color: #ffffff;
        }
    }

    .st-indicator {
        &__info {
            color: var(--secondary);
        }
    }

    .st-card__price::after {
        background-color: var(--gray-dark);
    }

    .ui.table {
        background: #2f2f2f !important;
    }

    .table th,
    .table td {
        border-top: 1px solid var(--gray-dark) !important;
        color: #fff;
    }

    .st-side-nav__icon {
        //border: solid 1px #444444;
        //background-color: var(--gray-dark);
    }

    .st-side-nav__item a:hover,
    .st-side-nav__item a.active {
        background-color: var(--gray-dark);
    }
    .st-side-nav__item a{
        border-bottom: 1px solid var(--gray-dark) !important;
    }

    .st-card {
        .st-input-wrap {
            .st-input-label {
                color: var(--secondary);

                &__amount {
                    color: var(--secondary);
                }
            }
        }

        &__price,
        &__prices {
            p {
                color: #fff !important;
            }
        }
    }

    .st-input {
        color: #ffffff;
    }

    .st-button {

        &--grey {
            color: var(--blue);
            border-color: var(--gray-dark);

            &:disabled {
                color: var(--grey);
            }
        }

        &--blue {
            box-shadow: 0 8px 15px 0 rgba(0, 118, 255, 0.41);

            &:hover {
                box-shadow: 0 1px 1px 0 rgba(25, 25, 25, 0.5);
            }
        }

        &--success {
            &:hover,
            &:focus {
                color: var(--white) !important;
                background-color: transparent;
            }
        }

        &--yellow {
            color: #fff;
            background-color: transparent;
            border-color: var(--yellow);
        
            &--no-border {
              border-radius: 0;
              box-shadow: none !important;
            }
        
            &:hover {
              color: var(--black);
              background-color: var(--yellow);
            }
        
            &:focus {
              color: var(--black);
              background-color: var(--yellow);
            }
          }

          &--purple {
            color: #ffffff;
            border-color: var(--purple);
            background-color: var(--purple);
            box-shadow: 0 8px 12px 0 rgb(35, 35, 35);
        
            &:hover {
              background-color: var(--purple);
              box-shadow: 0 1px 5px 0 rgb(35, 35, 35);
            }
          }
    }

    &--purple {
        color: #ffffff;
        border-color: #8d0d84;
        background-color: #8d0d84;
        box-shadow: 0 8px 12px 0 rgb(35, 35, 35);
    }

    .ui.segment {
        background: transparent;
    }

    .ui.selection {
        &.dropdown {
            background: var(--gray-dark);
            border-bottom: 1px solid var(--secondary);
        }

        &.active.dropdown {
            border: 1px solid #232323 !important;
        }
    }

    .ui.secondary.pointing.menu {
        border-bottom: 1px solid var(--gray-dark);

        .item {
            span {
                color: var(--white);
            }

            h5 {
                color: var(--secondary);
            }
        }
    }

    .ui.inverted.dimmer {
        background-color: rgba(35, 35, 35, 0.84);
    }

    .st-card-rex {
        &__left {
            border-right: 1px solid var(--gray-dark);
        }
    }

    .st-input {
        border-bottom: solid 1.3px var(--secondary);
    }

    .ui.table thead th {
        border-bottom: 1px solid var(--secondary);
    }

    .ui.celled.table tr td,
    .ui.celled.table tr th {
        border-left: 1px solid var(--secondary);
        color: #fff;
    }

    .ui.segment.active.tab p {
        color: var(--white);
    }





    .__floater__body {
        .react-joyride {
            &__tooltip {
                background: var(--gray-dark) !important;
                color: #fff !important;
                border: solid 2px var(--secondary);

                div button {
                    background-color: var(--blue) !important;
                    color: #fff !important;
                }
            }

            &__tooltip div {
                color: #fff !important;
            }
        }

        .__floater__arrow polygon {
            fill: var(--secondary);
        }
    }

    .react-joyride {
        &__beacon span {
            background: #0076ff8c !important;
            border-color: #0076ff !important;
        }
    }

    .mobile-menu-bar {
        background-color: var(--gray-dark);
        //border-top: solid 1px #2f2f2f;
    }
}