body,
html,
div,
p,
table,
tr,
td {
  color: #45576b;
}

#ual-box div{
  color: #fff !important;
}
/* width */
.st-main-body,
.st-main-body-swap {
  &::-webkit-scrollbar {
    width: 4.5px;
  }
}

.st-main-sidebar,
.st-main-body-swap {
  &::-webkit-scrollbar {
    width: 2.5px;
  }
}

/* Track */
.st-main-body,
.st-main-sidebar,
.st-main-body-swap {
  &::-webkit-scrollbar-track {
    background: var(--gray);
  }
}

/* Handle */
.st-main-body,
.st-main-sidebar,
.st-main-body-swap {
  &::-webkit-scrollbar-thumb {
    background: var(--purple);
  }
}

// Semantic ui global style changes

body {

  h1,
  h2,
  h3,
  h4,
  h5,
  input {
    font-family: 'ProximaNovaBold';
  }

  .ui.dropdown .menu {
    right: 0;
    left: unset;
  }

  .ui.radio.checkbox .box,
  .ui.radio.checkbox label {
    color: #45576b;
  }

  .ui.checkbox,
  .ui.radio.checkbox {
    input {

      &:active:checked~.box:before,
      &:active:checked~label:before,
      &:focus:checked~.box:before,
      &:focus:checked~label:before,
      &:checked~.box:before,
      &:checked~label:before {
        border-color: var(--purple);
        background-color: var(--purple);
      }

      &:active:checked~.box:after,
      &:active:checked~label:after,
      &:focus:checked~.box:after,
      &:focus:checked~label:after,
      &:checked~.box:after,
      &:checked~label:after {
        background-color: #fff;
      }
    }
  }

  .ui.inline.dropdown {
    padding: 0 10px;
    display: flex;
    align-items: center;

    .menu {
      margin-top: 10px;
      box-shadow: none;
      border-color: #e5ecf3;

      &>.header {
        font-size: 14px;
        color: #45576b;
      }

      .item {
        span {
          color: #728ead;
        }

        &.selected {
          span {
            color: var(--purple);
          }
        }

        &:hover {
          background-color: #e5ecf3;
        }
      }
    }
  }

  .ui.secondary.pointing.menu {
    border-bottom: 1px solid #e5ecf3;

    .item {
      display: flex;
      margin-bottom: 0;
      flex-direction: column;
      padding: 14px 0;
      border-bottom: solid 3px transparent;
      transition: all 0.3s;

      h5 {
        margin-top: 2px;
      }

      span {
        font-size: 18px;
        color: #45576b;
        font-weight: 600;
      }

      &:hover,
      &:focus {
        border-bottom: solid 3px var(--purple);
      }

      &.active {
        border-bottom: solid 3px var(--purple);

        &:focus,
        &:hover {
          border-color: var(--purple);
        }
      }
    }
  }

  .ui.inline.dropdown .dropdown.icon {
    margin: 0;
  }

  .ui.dropdown>.dropdown.icon::before {
    content: url('/assets/images/chevron-down.svg');
  }

  .ui.selection.dropdown {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #e5ecf3;
    border-radius: 0;
    transition: border-color 0.3s;

    &:hover,
    &:focus {
      border-color: var(--purple);
    }

    .menu>.item {
      color: #728ead;
      border: 0;

      &:hover {
        background-color: #e5ecf3;
      }
    }
  }

  .ui.selection {
    &.active.dropdown {
      border: 1px solid #e5ecf3;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      box-shadow: none;

      &:hover .menu,
      .menu {
        border: 1px solid #e5ecf3;
        box-shadow: none;
      }

      &:hover {
        border-color: #e5ecf3;
      }
    }
  }

  .ui.selection.active.dropdown {
    .menu {
      border: 1px solid #e5ecf3;
      box-shadow: none;
    }
  }

  .ui.dropdown>.text,
  .ui.selection.visible.dropdown>.text:not(.default) {
    color: #45576b;
  }

  .ui.dropdown:not(.button)>.default.text {
    font-size: 13px;
    color: #c3d2e3;
    text-transform: uppercase;
  }

  .ui.popup {
    z-index: 9999;
    padding: 5px 10px;
    box-shadow: none;
    border-color: #e5ecf3;

    &::before {
      display: none;
    }
  }

  .ui.table {
    border: 0;
    margin-top: 0;
    font-size: 13px;

    tr {
      td {
        border-top: 1px solid #e5ecf3;
      }
    }

    thead {
      tr {
        td {
          border-bottom: 1px solid #e5ecf3;
        }
      }
    }
  }

  .ui.inverted.dimmer .ui.loader:after {
    border-color: var(--purple) transparent transparent;
  }

  .ui.menu {
    font-family: 'ProximaNovaBold';
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.st-header {
  z-index: 9999;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  min-height: 75px;
  padding: 0 20px;
  display: flex;
  background-color: var(--midnight);
  //border-bottom: solid 1px #c3c3c3;

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .ui.inline.dropdown>.text {
    display: none;
  }
}

.st-burger {
  cursor: pointer;
  position: relative;
  height: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;

  &__item {
    height: 3px;
    width: 20px;
    opacity: 1;
    background-color: var(--purple);
    position: relative;
    transform: translateY(0) rotate(0);
    transition: all 0.3s;
  }

  &--active {
    .st-burger__item {
      &:nth-child(1) {

        transform: translateY(7px) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: translate(20px) rotate(45deg);
      }

      &:nth-child(3) {

        transform: translateY(-7px) rotate(-45deg);
      }
    }
  }
}

.st-main-wrapper {

  //apply this whyle sidebar is opened
  &--side-active {
    .st-main-sidebar {
      &__search {
        transform: translateX(0);
      }
    }

    .st-side-nav {
      &__item {
        a {
          span {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    }
  }
}

.st-main-sidebar {
  display: none;
  direction: rtl;
  overflow-x: hidden;
  overflow-y: auto;
  width: 70px;
  flex-shrink: 0;
  padding: 0 0 15px 0;
  position: relative;
  border-right: solid 1px #c3c3c3;
  transition: all 0.3s;
  background-color: #ffffff;

  &__toggle.ui.button {
    top: 0;
    right: 0;
    z-index: 2;
    margin: 0;
    position: absolute;
    padding: 15px 10px;
    background-color: #ffffff;
    border-radius: 0;

    img {
      transform: rotate(0);
      transition: all 0.3s;
    }

    &:hover,
    &:focus {
      background-color: #ffffff;
    }
  }

  &__search {
    padding: 0 15px;
    margin-bottom: 24px;
    min-height: 31px;
    transform: translateX(-100%);
    transition: transform 0.3s;

    .st-input-wrap {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.st-top-header {
  max-width: 1170px;
  padding: 70px 30px 0px 30px;
  margin-bottom: -5rem !important;
  margin: auto;

  .content,
  .sub.header {
    color: #fff !important;
  }

  .st-card {
    background-color: transparent;
    border: none;

    .st-button--large {
      margin: auto;
    }
  }

  .ui.message {
    width: 100%;
    padding: 30px !important;
    margin-bottom: 3rem !important;
    background-color: var(--purple);
    box-shadow: none;

    .st-button,
    .ui.button.st-button {
      color: var(--purple);
      border: solid 1px var(--white);
      background-color: var(--white);
      margin: auto;
    }
  }

  .close.icon {
    z-index: 999;
    color: var(--gray-dark);
  }
}

.st-main-body {
  flex-shrink: 0;
  background-color: var(--gray);
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.st-main-body-rex {
  background-color: #f5f8fa;
  max-width: 100%;
  height: 100% !important;
  margin: 0 auto;
}

.st-main-body-swap {
  overflow-y: scroll;
  padding: 0;
  width: 100%;
}

.st-main-rotator {
  max-width: 1170px;
  padding: 70px 30px 0 30px;
  margin: 0 auto;

  .st-card {
    height: 100%;
    position: relative;
    background-color: #ffffff;

    img {
      max-height: 200px;
      margin: auto !important;
    }

    &__featured {
      height: 100%;
      padding: 7px 0 7px 5px;
      border-radius: 0 0 0 4px;
      border-top: solid 1px #e5ecf3;
      border-left: solid 1 #e5ecf3;
      border-right: none;
      border-bottom: solid 1px #e5ecf3;
    }

    &__promos {
      height: 100%;
      position: relative;
      padding: 7px 5px 7px 0;
      border-radius: 0 4px 4px 0;
      border-top: solid 1px #e5ecf3;
      border-left: none;
      border-right: solid 1 #e5ecf3;
      border-bottom: solid 1px #e5ecf3;
      background-color: #ffffff;
    }

    &__minheight {
      min-height: 0;
      padding-bottom: 0rem;
    }

    div {
      padding: 0px 0px !important;
    }
  }
}

.st-main-body-inner {
  max-width: 1170px;
  padding: 70px 30px 70px 30px;
  margin: 0 auto;
}

.st-main-body-rex {
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
}

.st-main-body-wrapper {
  padding-top: 70px;
  display: flex;
  max-height: 100vh;
  overflow: hidden;
}

.st-login-wrap {
  display: flex;

  &--theme-switch {
    border-left: solid 1.2px var(--gray);
  }

  .btn-arrow {
    color: var(--purple);
    margin-right: 0.5rem;
    -webkit-animation: bounceRight 2s infinite;
    animation: bounceRight 2s infinite;
    float: right;
  }
}

.st-network-wrap {
  display: flex;
  margin-left: 1rem;

  .btn-arrow {
    color: var(--purple);
    margin-right: 0.5rem;
    -webkit-animation: bounceRight 2s infinite;
    animation: bounceRight 2s infinite;
    float: right;
  }
}

//top menu navigation
.st-top-menu {
  list-style: none;
  display: flex;
  width: auto;
  padding: 0;
  margin: 0 0 0 40px;

  .ui.dropdown {
    .menu {
      width: 100%;
    }
  }

  li {
    &:not(:last-child) {
      margin-right: 40px;
    }

    a,
    .menu-dropdown {
      color: #c3d2e3;
      font-size: 13px;
      display: inline-block;
      padding: 21px 0;
      text-transform: uppercase;
      transition: all 0.3s;
      box-shadow: inset 0 0px 0 var(--purple);

      &:hover,
      &:focus,
      &.active {
        box-shadow: inset 0 -2px 0 var(--purple);

        .text {
          color: var(--purple) !important;
        }
      }
    }
  }
}

//sidebar navigation

.st-side-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  direction: ltr;

  &__item {
    //margin-bottom: 6px;
    //border-bottom: 2px solid transparent;

    a {
      padding: 20px 15px;
      display: flex;
      font-size: 20px;
      line-height: 150%;
      align-items: center;
      border-right: 2px solid transparent;
      transition: border-color 0.3s;
      color: #571AFF;
      border-bottom: 1px solid #DFDFDF;

      &:hover {
        color: #571AFF;
      }

      &.active {
        //border-color: var(--purple);
      }

      img {
        margin: 0 !important;
        align-self: center;
        flex-shrink: 0;
        max-height: 32px;
      }

      span {
        display: inline-block;
        margin-left: 10px;
        opacity: 0;
        transform: translateX(50px);
        transition: opacity, transform 0.5s;
      }
    }
  }

  &__icon {
    padding: 1px;
    
  }
}

.st-footer {
  padding-top: 35px;
  padding-bottom: 35px;

  &__copy {
    display: flex;
    align-items: center;

    span {
      margin-right: 17px;
    }

    &.staker {
      margin-top: 4px;

      span {
        position: relative;
        top: 2px;
      }

      img {
        height: 21px;
      }
    }
  }

  &__item {
    padding: 0 20px;
  }
}

// Buttons styles
button {
  border: 0;
  padding: 0;
  background-color: transparent;

  &.st-logout {
    margin-left: 20px;
    margin-right: -10px;
    //padding-right: 11px;
  }
}

.st-button,
.ui.button.st-button {
  color: var(--purple);
  display: flex;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  padding: 9px 15px;
  border-radius: 20px;
  background-color: transparent;
  border: solid 1px var(--purple);
  transition: all 0.3s;
  justify-content: center;

  &--theme-switcher {
    padding: 10px;
    margin-right: 0 !important;
    border: none;

    img {
      margin: auto !important;
    }

    &:hover,
    &:focus {
      background-color: transparent !important;
    }
  }

  &:hover,
  &:focus {
    outline: none !important;
    color: #fff;
    background-color: var(--purple);
  }

  img {
    margin-right: 12px;
  }

  &--small {
    font-size: 13px;
  }

  &--login {
    font-size: 13px;

    span {
      color: inherit;
      display: inline-block;
      margin-left: 3px;
    }
  }


  &--login {
    color: var(--white);
    background-color: var(--purple);
    border-color: var(--purple);

    &--no-border {
      border-radius: 0;
      box-shadow: none !important;
    }
  }


  &--purple {
    color: #ffffff;
    background-color: var(--purple);

    &--no-border {
      border-radius: 0;
      box-shadow: none !important;
    }
  }

  &--yellow {
    color: #010035;
    background-color: var(--yellow);
    border-color: var(--yellow);

    &--no-border {
      border-radius: 0;
      box-shadow: none !important;
    }

    &:hover {
      color: var(--black);
      background-color: var(--yellow);
    }

    &:focus {
      color: var(--black);
      background-color: var(--yellow);
    }
  }

  &--gray {
    color: var(--purple);
    border: solid 1px var(--purple) !important;
    background-color: var(--dark-gray);

    &:hover {
      color: var(--dark-gray);
      background-color: transparent;
    }

    &:focus {
      color: var(--dark-gray);
      background-color: transparent;
    }
  }

  &--red:disabled {
    color: #ffffff;
    background-color: #fc2c03;
    border-color: #fc2c03;
    box-shadow: 0 8px 12px 0 rgba(252, 44, 3, 0.32);
  }

  &--large {
    font-size: 14px;
    padding: 16px 30px;
    border-radius: 25px;
    text-transform: uppercase;
    box-shadow: 0 8px 12px 0 rgba(73, 128, 190, 0.5);
    transition: all 0.3s;

    &:hover {
      box-shadow: 0 4px 6px 0 rgba(73, 128, 190, 0.5);
    }
  }

  &--success {

    &:hover,
    &:focus {
      color: var(--purple);
      background-color: transparent;
    }
  }

  &--purple {
    color: #ffffff;
    border-color: var(--purple);
    background-color: var(--purple);
    box-shadow: 0 8px 12px 0 rgba(147, 73, 190, 0.34);

    &:hover {
      background-color: var(--purple);
      box-shadow: 0 4px 6px 0 rgba(147, 73, 190, 0.34);
    }
  }

  &--grey {
    color: #e5ecf3;
    border-color: #e5ecf3;

    &:hover,
    &:focus {
      color: var(--purple);
      border-color: var(--purple);
      background-color: transparent;
    }
  }

  &--success {

    &:hover,
    &:focus {
      color: var(--white) !important;
      background-color: transparent;
    }
  }
}

// forms styles 

.st-input-wrap {
  position: relative;
  margin-bottom: 20px;
  direction: ltr;
}

.st-input {
  color: #45576b;
  border: 0;
  width: 100%;
  font-size: 18px;
  padding: 20px 0 10px;
  border-bottom: solid 1px #e5ecf3;

  &::placeholder {
    color: #45576b;
  }

  &:focus {
    &+.st-input-label {
      top: -1px;
      font-size: 10px;
      transform: translateY(0);
    }
  }

  &--number {
    padding: 13px 0;
    text-align: center;
    font-size: 26px;
    color: var(--purple);

    &::placeholder {
      text-align: center;
      font-size: 26px;
      color: var(--purple);
    }
  }
}

// .st-input
.st-input:focus,
.st-input:not(:placeholder-shown) {
  &+.st-input-label {
    top: -2px;
    font-size: 10px;
    transform: translateY(0);
  }
}

.st-input-label {
  top: 50%;
  left: 0;
  position: absolute;
  color: #c3d2e3;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s;
  transform: translateY(-50%);

  &__amount {
    top: 12%;
    left: 0;
    position: absolute;
    color: #c3d2e3;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3s;
    transform: translateY(-50%);
  }

  &__amount:focus {
    top: -2px;
    font-size: 10px;
    transform: translateY(0);
  }
}

.st-label {
  color: #c3d2e3;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.st-radios-wrapper {
  margin-bottom: 20px;

  .field+.field {
    margin-left: 10px;
  }
}

// card styles

.divider-horizontal {
  width: calc(100% + 60px);
  position: absolute;
  height: 1px;
  left: -30px;
  bottom: 0;
  background-color: var(--gray);
}

.st-card-col {
  margin-bottom: 29px;
}

.st-card {
  height: 100%;
  position: relative;
  padding: 28px 30px;
  border-radius: 4px;
  background-color: var(--white);

  &__token-info {
    display: flex;
    align-items: center;
    margin-bottom: 36px;

    .ui.image {
      max-height: 48px;
    }
  }

  &__socials {
    padding-top: 15px;

    i.icon {
      color: var(--purple);
      font-size: 25px;
      margin-right: 10px;
    }

    img {
      margin-right: 20px;
      float: left;
    }
  }

  &__token-name {
    display: inline-block;
    margin-left: 22px;
    font-size: 18px;
  }

  &__return {
    display: flex;
    position: relative;
    padding-bottom: 14px;
    justify-content: space-between;
  }

  &__prices {
    display: flex;
    position: relative;
    padding-bottom: 24px;
    justify-content: space-between;
  }

  &__price {
    width: 26%;
    position: relative;

    &::after {
      content: '';
      top: 0;
      right: 0;
      position: absolute;
      display: inline-block;
      height: 35px;
      width: 1px;
      background-color: #e5ecf3;
    }

    p {
      color: #45576b;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__price-max,
  &__price-min {
    width: 37%;
    display: flex;
    color: #45576b;
    justify-content: flex-end;

    img {
      margin-top: 3px;
      margin-right: 10px;
      align-self: flex-start;
    }
  }

  &__price-nob {
    width: 26%;
    position: relative;

    &::after {
      content: '';
      top: 0;
      right: 0;
      position: absolute;
      display: inline-block;
      height: 35px;
      width: 0px;
      background-color: #e5ecf3;
    }

    p {
      color: #45576b;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__split-min {
    width: 40%;
    display: flex;
    margin-top: 1.5rem;
    color: #45576b;
    justify-content: flex-start;

    img {
      margin-top: 3px;
      margin-right: 10px;
      align-self: flex-start;
    }
  }

  &__split-max {
    width: 40%;
    display: flex;
    margin-top: 1.5rem;
    color: #45576b;
    justify-content: flex-start;

    img {
      margin-top: 3px;
      margin-right: 10px;
      align-self: flex-start;
      transform: rotate(180deg);
    }
  }

  &__price-max {
    img {
      transform: rotate(180deg);
    }
  }

  &__description {
    padding-top: 23px;
  }

  h5 {
    color: var(--gray);
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 2px;

    &.st-card__headline {
      margin-bottom: 36px;
    }
  }

  .st-card__popup {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    padding: 1rem !important;
  }

  .st-input-wrap {
    width: 100%;
  }

  .row {
    margin-left: -13px;
    margin-right: -13px;

    .col {
      padding: 0 13px;
    }

    &.no-gutters {
      margin: 0;

      .col {
        padding: 0;
      }
    }
  }

  .st-button {
    width: 100%;

    &__tiny {
      width: 150px;
      padding: 5px !important;
    }

    &.mgt {
      margin-top: 25px;
    }

    &--large {
      margin-top: 25px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      max-width: 271px;
    }
  }

  &__bold {
    color: #45576b;
    font-size: 18px;
    margin-bottom: 0;
  }

  &__bold-color {
    color: var(--purple);
    font-size: 18px;
  }

  &__number {
    color: var(--purple);
    font-size: 26px;
    letter-spacing: -0.3px;

    &--purple {
      color: var(--purple);
    }
  }

  &__info {
    display: flex;
    text-transform: uppercase;
    padding-top: 30px;

  }

  .st-resources__balance {
    font-size: 20px;
    margin-bottom: 13px;
  }

  &__info-icon {
    cursor: pointer;
    margin-left: 6px;
    display: inline-block;
  }

  // for tabs specific elements
  &__lend-select {
    margin-bottom: 75px;
  }

  &__lend {
    margin-bottom: 50px;

    .st-card__number {
      padding: 13px 0;
    }

    .st-input--number {
      margin-bottom: 12px;
    }

    .st-button--large {
      margin-top: 59px;
      margin-bottom: 0;
    }
  }

  &__minheight {
    min-height: 500px;
    padding-bottom: 4rem;
  }

  .st-button--claim {
    margin-top: 85px;
  }

  &__rex-menu {
    border-bottom: solid 1px var(--gray);
    padding: 22px;
    margin: 20px 0;
  }

  .uppercase {
    text-transform: uppercase;
  }
}

// resources card specific styles
.st-resources {
  display: flex;
  padding: 0 30px;

  &__red {
    font-size: 16px;
    margin-top: 8px !important;
    margin-bottom: 13px;
    color: #cc5151;
  }

  &__green {
    font-size: 16px;
    margin-top: 8px !important;
    margin-bottom: 13px;
    color: #229b46;
  }

  &__side {
    padding: 28px 0;
    width: 240px;
    flex-shrink: 0;
    border-right: 1px solid #e5ecf3;
  }

  &__main {
    width: 100%;
    padding: 80px 0 80px 0;
  }

  &__options {
    position: absolute;
    top: 30px;
    right: 30px;
    color: var(--gray);
    transition: all 0.3s;
  }

  &__options:hover {
    color: var(--purple);
    transform: scale(1.2);
  }

  &__options:active {
    transform: scale(.8);
  }

  &__icon {
    margin-bottom: 12px;
  }

  &__title {
    color: #45576B;
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 7px;
  }

  &__balance {
    font-size: 26px;
    color: var(--purple);
    margin-top: 0;
    line-height: 32px;
    margin-bottom: 19px;
  }

  &__info {
    p {
      font-size: 16px;
      line-height: 20px;
    }

    li:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.st-indicators {

  li:not(:last-child) {
    margin-bottom: 67px;
  }
}

.st-indicator {
  display: flex;

  &__left {
    margin-right: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }

  &__right {
    width: 100%;
    max-width: 271px;

    p {
      font-size: 13px;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 9px;
  }

  .ui.progress {
    height: 4px;
    margin-top: 0;
    margin-bottom: 7px;

    .bar {
      height: 4px;
      background-image: linear-gradient(to left, var(--purple), var(--purple));
    }
  }

  .ui.indicating.progress[data-percent^="6"] {
    .bar {
      height: 4px;
      background-image: linear-gradient(to left, #D810F6, var(--purple));
    }
  }

  .ui.indicating.progress[data-percent^="7"] {
    .bar {
      height: 4px;
      background-image: linear-gradient(to left, #D810F6, var(--purple));
    }
  }

  .ui.indicating.progress[data-percent^="8"] {
    .bar {
      height: 4px;
      background-image: linear-gradient(to left, #D810F6, var(--purple));
    }
  }

  .ui.indicating.progress[data-percent^="9"] {
    .bar {
      height: 4px;
      background-image: linear-gradient(to left, #D810F6, rgb(150, 7, 172));
    }
  }

  .ui.indicating.progress[data-percent^="10"] {
    .bar {
      height: 4px;
      background-image: linear-gradient(to left, #D810F6, #D810F6);
    }
  }
}

.st-pannel-inner {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
}

.st-card-rex {
  padding: 0 30px;
  display: flex;
  height: 100%;
  min-height: 800px;

  &__left,
  &__right {
    padding: 28px 0;
  }

  &__left {
    width: 30.4%;
    padding-right: 30px;
    border-right: 1px solid #e5ecf3;
  }

  &__right {
    width: 69.6%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .st-button--small {
    outline: none;
    padding: 7px 15px;
    border-width: 2px;
  }

  &__buttons {
    margin-bottom: 21px;
  }

  .ui.secondary.item.menu {
    margin-bottom: 60px;
  }

  .ui.segment {
    border: 0;
    margin-top: 0;
    padding: 0;
    box-shadow: none;
  }

  .st-input-wrap {
    margin-top: 20px;
  }
}

.swal2-container.swal2-top.swal2-shown {
  width: 400px;
}

.swal2-container.swal2-top-end.swal2-shown {
  width: 400px;
}

.swal2-popup {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.137) !important;
}

.swal2-container.swal2-shown {
  background-color: rgba(255, 255, 255, 0.39);
}

.swal2-connect {
  background: var(--purple) !important;
  width: 100% !important;
  height: 3.5rem !important;
  margin-top: 5.5rem;

  #swal2-content {
    color: #ffffff !important;
  }

  .swal2-footer a {
    color: #fff !important;
  }

  .swal2-actions .swal2-loading {
    .swal2-confirm .swal2-styled {
      border-left-color: #000 !important;
      border-right-color: rgb(255, 255, 255);
    }
  }
}

.swal2-connected {
  background: var(--purple) !important;
  width: 100% !important;
  height: 3.5rem !important;
  margin-top: 5.5rem;

  #swal2-content {
    color: #ffffff !important;
  }

  .swal2-actions .swal2-loading {
    .swal2-confirm .swal2-styled {
      border-left-color: #000 !important;
      border-right-color: rgb(255, 255, 255);
    }
  }
}

.swal2-transaction {
  background: var(--purple) !important;
  width: 100% !important;
  height: 7rem !important;
  margin-top: 5.5rem;

  .swal2-header {
    margin-top: -20px;

    .swal2-close {
      position: static;
      width: .8em;
      height: .8em;
      line-height: .8;
      color: var(--white) !important;
    }
  }

  #swal2-content {
    color: #ffffff !important;
  }

  .swal2-footer a {
    color: #fff !important;
  }

  .swal2-actions .swal2-loading {
    .swal2-confirm .swal2-styled {
      border-left-color: #000 !important;
      border-right-color: rgb(255, 255, 255);
    }
  }
}

.swal2-err {
  background: red !important;
  width: 100% !important;
  height: 3.5rem !important;
  margin-top: 5.5rem;

  #swal2-content {
    color: #ffffff !important;
  }
}

@media only screen and (max-width: 850px) and (orientation : landscape) {
  .st-top-header {
    max-width: 1170px;
    padding: 70px 30px 0px 30px !important;
    margin-bottom: -5rem !important;
    margin: auto;
  }
}

@media only screen and (max-width: 850px) and (orientation : portrait) {
  .st-top-header {
    padding: 70px 0px 0px 0px !important;
  }
}

@media only screen and (max-width: 850px) {

  .st-login-wrap {
    &--theme-switch {
      display: none !important;
    }
  }

  .swal2-container.swal2-top.swal2-shown {
    width: 300px;
  }

  .swal2-container.swal2-top-end.swal2-shown {
    width: 300px;
  }

  .st-button {
    &--theme-switcher {
      margin-left: auto !important;
    }
  }

  .st-button--login,
  .ui.button.st-button--login {
    img.ui.image {
      display: none;
    }
  }

  .st-network-wrap {
    //display: none;
    margin-left: 1rem;
    float: right;

    .btn-arrow {
      color: var(--purple);
      margin-right: 0.5rem;
      -webkit-animation: bounceRight 2s infinite;
      animation: bounceRight 2s infinite;
      float: right;
    }
  }

  .st-main-rotator {
    max-width: 1170px;
    padding: 70px 0px 0px 0px;
    margin: 0 auto;

    .banner {
      height: 5rem !important;
      background-position: center;
      background-size: cover !important;
      border-radius: 4px;
      border: solid 0px #e5ecf3;
      text-align: center;
    }
  }

  .st-header {
    padding: 15px;

    &__left {
      max-width: 100px !important;
      display: flex;
      align-items: center;
    }
  }

  .st-network-wrap {
    display: none;
  }

  .st-top-menu {
    margin-left: 20px;
    display: none;
  }

  .mobile-menu-bar {
    background-color: var(--midnight);
    //border-top: solid 1px var(--purple);
    margin-top: 60px;
    padding: 1rem;
    display: flex;
    align-items: center;

    .theme-switcher {
      margin-left: auto !important;
    }
  }

  .st-card-rex {
    padding: 0 30px;
    display: flex;
    height: 100%;

    &__left,
    &__right {
      padding: 28px 0;
    }

    &__left {
      width: 30.4%;
      padding-right: 30px;
      border-right: none !important;
    }
  }
}

@media only screen and (min-width: 769px) {
  .st-main-body {
    //width: calc(100% - 70px);
    width: 100%;
  }

  .st-header {
    .st-burger {
      display: none;
    }
  }

  .st-main-wrapper {

    //apply this whyle sidebar is opened
    &--side-active {
      .st-main-sidebar {
        width: 219px;

        &__toggle.ui.button {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .st-main-sidebar {
    &__search {
      height: 45px;

      .st-input-wrap {
        max-width: 141px;
      }
    }

    .st-top-menu {
      display: none;
    }

    //Mobile Menu
    .mobile-menu-bar {
      display: none;
    }
  }

  .st-side-nav {
    &__item {
      a {
        span {
          color: var(--purple) !important;
          // opacity: 0;
          // transform: translateX(50px);
        }
      }
    }
  }
}


@media only screen and (max-width: 768px) {
  .header-arrow {
    display: none !important;
  }

  .st-main-body-wrapper {
    padding-top: 59px;
  }

  .st-main-wrapper {
    &--side-active {
      .st-main-sidebar {
        transform: translateX(0);
        border-right: none !important;
      }
    }
  }

  .st-main-body {
    width: 100%;
    padding: 15px;
  }

  .st-header {

    &__left {
      max-width: 60px;
      display: flex;
      align-items: center;
    }

    &__right {
      margin-left: auto;
    }

    .st-button--success {
      span {
        display: none;
      }
    }
  }

  .st-button--login,
  .ui.button.st-button--login {
    font-size: 12px;
    padding: 7px 12px;
    margin-right: 10px;
    line-height: 1rem;

    img.ui.image {
      display: none;
    }
  }

  button.st-logout {
    margin-left: 0;
  }

  .st-resources {
    &__side {
      width: 180px;
    }

    &__main {
      padding: 40px 0 40px 0;
    }

    &__options {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }

  .st-main-sidebar {
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1001;
    position: absolute;
    transform: translateX(-100%);
    transition: all 0.2s;
    direction: ltr;
    padding-bottom: 10rem;

    &__toggle.ui.button {
      display: none;
    }

    .st-top-menu {
      display: block;
      margin-top: 22px;

      li {
        margin-bottom: 20px;

        &:not(:last-child) {
          margin-right: 5px;
          float: left;
        }

        a {
          padding: 10px 10px;
          font-size: 12px;
        }
      }
    }

  }

  .st-side-nav__item {
    a {
      border-right: 5px solid transparent;

      &:hover,
      &.active {
        background-color: #c3d2e3;
      }
    }
  }

  .st-card-rex {
    padding: 0 15px;
    flex-direction: column;
    height: auto;

    &__left {
      width: 100%;
      padding-right: 0;
      border-right: 0;
    }

    &__right {
      width: 100%;
      padding: 0 0 28px;
    }
  }

  body {
    .ui.secondary.pointing.menu {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 50%;
        cursor: pointer;

        span {
          font-size: 14px;
        }

        h5 {
          font-size: 12px;
        }
      }
    }

    .mgbot-sm {
      margin-bottom: 20px;
    }
  }

  .react-multi-carousel-dot-list {
    position: absolute;
    background: transparent;
    width: 15%;
    margin: auto;
    bottom: -30px;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    padding: 0;
    list-style: none;
    text-align: center;
    border-radius: 50px;
  }
}

@media only screen and (max-width: 550px) {
  .resources__sideRight {
    padding-left: 0px;
  }

  .header-message {
    .st-card {
      padding: 0px !important;
    }

    h1,
    h2 {
      font-size: 1.514286rem !important;
    }
  }

  .st-main-body-wrapper {
    padding-top: 10px;
  }

  .content {
    padding: 0 !important;
    text-align: center;
  }

  .st-main-body-inner {
    max-width: 1170px;
    padding: 50px 0px 70px 0px;
    margin: 0 auto;
  }

  .st-main-body-rex {
    max-width: 100%;
    height: 100%;
  }

  .st-footer {
    padding-bottom: 100px;

    .justify-content-center {
      flex-direction: column !important;
    }

    &__item {
      margin-bottom: 20px;
    }

    &__copy {
      justify-content: center;
    }
  }

  .st-resources {
    flex-direction: column;

    &__side {
      border: 0 !important;
      width: 100%;
    }

    &__main {
      padding-left: 0;
    }
  }

  .ui.active.modal {
    display: block;
    width: 90%;
    height: 100%;
    margin-top: 10rem !important;
    margin: auto;
    background: var(--purple);
  }

  .ui.modal .content>.description {
    display: block;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none;
  }
}

.__floater__body {
  .react-joyride {
    &__tooltip {
      div button {
        background-color: var(--purple) !important;
        color: #fff !important;
      }
    }
  }

  .__floater__arrow polygon {
    fill: var(--secondary);
  }
}

.react-joyride {
  &__beacon span {
    background: var(--purple) !important;
    border-color: var(--purple) !important;
  }
}